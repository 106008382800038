export default {
  'REQUEST_QUESTION_ANSWER_COLLECTION': 'questionAnswer/REQUEST_QUESTION_ANSWER_COLLECTION',
  'SUCCESS_QUESTION_ANSWER_COLLECTION': 'questionAnswer/SUCCESS_QUESTION_ANSWER_COLLECTION',
  'ERROR_QUESTION_ANSWER_COLLECTION': 'questionAnswer/ERROR_QUESTION_ANSWER_COLLECTION',

  'REQUEST_QUESTION_ANSWER': 'questionAnswer/REQUEST_QUESTION_ANSWER',
  'SUCCESS_QUESTION_ANSWER': 'questionAnswer/SUCCESS_QUESTION_ANSWER',
  'ERROR_QUESTION_ANSWER': 'questionAnswer/ERROR_QUESTION_ANSWER',

  'UPDATE_QUESTION_ANSWER': 'questionAnswer/UPDATE_QUESTION_ANSWER',
  'SUCCESS_UPDATE_QUESTION_ANSWER': 'questionAnswer/SUCCESS_UPDATE_QUESTION_ANSWER',
  'ERROR_UPDATE_QUESTION_ANSWER': 'questionAnswer/ERROR_UPDATE_QUESTION_ANSWER',

  'DELETE_QUESTION_ANSWER': 'questionAnswer/DELETE_QUESTION_ANSWER',
  'SUCCESS_DELETE_QUESTION_ANSWER': 'questionAnswer/SUCCESS_DELETE_QUESTION_ANSWER',
  'ERROR_DELETE_QUESTION_ANSWER': 'questionAnswer/ERROR_DELETE_QUESTION_ANSWER',
};
