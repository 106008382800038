export default {
  'REQUEST_ITEM_TYPE_COLLECTION': 'itemType/REQUEST_ITEM_TYPE_COLLECTION',
  'SUCCESS_ITEM_TYPE_COLLECTION': 'itemType/SUCCESS_ITEM_TYPE_COLLECTION',
  'ERROR_ITEM_TYPE_COLLECTION': 'itemType/ERROR_ITEM_TYPE_COLLECTION',

  'REQUEST_ITEM_TYPE': 'itemType/REQUEST_ITEM_TYPE',
  'SUCCESS_ITEM_TYPE': 'itemType/SUCCESS_ITEM_TYPE',
  'ERROR_ITEM_TYPE': 'itemType/ERROR_ITEM_TYPE',

  'UPDATE_ITEM_TYPE': 'itemType/UPDATE_ITEM_TYPE',
  'SUCCESS_UPDATE_ITEM_TYPE': 'itemType/SUCCESS_UPDATE_ITEM_TYPE',
  'ERROR_UPDATE_ITEM_TYPE': 'itemType/ERROR_UPDATE_ITEM_TYPE',

  'DELETE_ITEM_TYPE': 'itemType/DELETE_ITEM_TYPE',
  'SUCCESS_DELETE_ITEM_TYPE': 'itemType/SUCCESS_DELETE_ITEM_TYPE',
  'ERROR_DELETE_ITEM_TYPE': 'itemType/ERROR_DELETE_ITEM_TYPE',
};
