export default {
  'REQUEST_INSTITUTION_COLLECTION': 'institution/REQUEST_INSTITUTION_COLLECTION',
  'SUCCESS_INSTITUTION_COLLECTION': 'institution/SUCCESS_INSTITUTION_COLLECTION',
  'ERROR_INSTITUTION_COLLECTION': 'institution/ERROR_INSTITUTION_COLLECTION',

  'REQUEST_INSTITUTION': 'institution/REQUEST_INSTITUTION',
  'SUCCESS_INSTITUTION': 'institution/SUCCESS_INSTITUTION',
  'ERROR_INSTITUTION': 'institution/ERROR_INSTITUTION',

  'UPDATE_INSTITUTION': 'institution/UPDATE_INSTITUTION',
  'SUCCESS_UPDATE_INSTITUTION': 'institution/SUCCESS_UPDATE_INSTITUTION',
  'ERROR_UPDATE_INSTITUTION': 'institution/ERROR_UPDATE_INSTITUTION',

  'DELETE_INSTITUTION': 'institution/DELETE_INSTITUTION',
  'SUCCESS_DELETE_INSTITUTION': 'institution/SUCCESS_DELETE_INSTITUTION',
  'ERROR_DELETE_INSTITUTION': 'institution/ERROR_DELETE_INSTITUTION',
};
