export default {
  AUDIENCE_STORE: 'AUDIENCE_STORE',
  PROGRAM_STORE: 'PROGRAM_STORE',
  CLOSED_CAPTION_STORE: 'CLOSED_CAPTION_STORE',
  CLOUDWATCH_STORE: 'CLOUDWATCH_STORE',
  COUNTRY_STORE: 'COUNTRY_STORE',
  COURSE_ITEM_STORE: 'COURSE_ITEM_STORE',
  COURSE_STORE: 'COURSE_STORE',
  DOCUMENT_STORE: 'DOCUMENT_STORE',
  EVENT_STORE: 'EVENT_STORE',
  IMAGE_STORE: 'IMAGE_STORE',
  INSTITUTION_STORE: 'INSTITUTION_STORE',
  INSTRUCTOR_STORE: 'INSTRUCTOR_STORE',
  ITEM_TYPE_STORE: 'ITEM_TYPE_STORE',
  LANGUAGE_STORE: 'LANGUAGE_STORE',
  LOGIN_STORE: 'LOGIN_STORE',
  PROGRAM_STORE: 'PROGRAM_STORE',
  QUESTION_ANSWER_STORE: 'QUESTION_ANSWER_STORE',
  QUESTION_STORE: 'QUESTION_STORE',
  QUESTION_TYPE_STORE: 'QUESTION_TYPE_STORE',
  QUIZ_CONTEXT_STORE: 'QUIZ_CONTEXT_STORE',
  QUIZ_STORE: 'QUIZ_STORE',
  REPORT_STORE: 'REPORT_STORE',
  TOPIC_STORE: 'TOPIC_STORE',
  VIDEO_SOURCE_STORE: 'VIDEO_SOURCE_STORE',
  VIDEO_STORE: 'VIDEO_STORE',
};