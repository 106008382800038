export default {
  'REQUEST_TOPIC_COLLECTION': 'topic/REQUEST_TOPIC_COLLECTION',
  'SUCCESS_TOPIC_COLLECTION': 'topic/SUCCESS_TOPIC_COLLECTION',
  'ERROR_TOPIC_COLLECTION': 'topic/ERROR_TOPIC_COLLECTION',

  'REQUEST_TOPIC': 'topic/REQUEST_TOPIC',
  'SUCCESS_TOPIC': 'topic/SUCCESS_TOPIC',
  'ERROR_TOPIC': 'topic/ERROR_TOPIC',

  'UPDATE_TOPIC': 'topic/UPDATE_TOPIC',
  'SUCCESS_UPDATE_TOPIC': 'topic/SUCCESS_UPDATE_TOPIC',
  'ERROR_UPDATE_TOPIC': 'topic/ERROR_UPDATE_TOPIC',

  'DELETE_TOPIC': 'topic/DELETE_TOPIC',
  'SUCCESS_DELETE_TOPIC': 'topic/SUCCESS_DELETE_TOPIC',
  'ERROR_DELETE_TOPIC': 'topic/ERROR_DELETE_TOPIC',
};
