export default {
  'REQUEST_COURSE_ITEM_COLLECTION': 'courseItem/REQUEST_COURSE_ITEM_COLLECTION',
  'SUCCESS_COURSE_ITEM_COLLECTION': 'courseItem/SUCCESS_COURSE_ITEM_COLLECTION',
  'ERROR_COURSE_ITEM_COLLECTION': 'courseItem/ERROR_COURSE_ITEM_COLLECTION',

  'REQUEST_COURSE_ITEM': 'courseItem/REQUEST_COURSE_ITEM',
  'SUCCESS_COURSE_ITEM': 'courseItem/SUCCESS_COURSE_ITEM',
  'ERROR_COURSE_ITEM': 'courseItem/ERROR_COURSE_ITEM',

  'UPDATE_COURSE_ITEM': 'courseItem/UPDATE_COURSE_ITEM',
  'SUCCESS_UPDATE_COURSE_ITEM': 'courseItem/SUCCESS_UPDATE_COURSE_ITEM',
  'ERROR_UPDATE_COURSE_ITEM': 'courseItem/ERROR_UPDATE_COURSE_ITEM',

  'DELETE_COURSE_ITEM': 'courseItem/DELETE_COURSE_ITEM',
  'SUCCESS_DELETE_COURSE_ITEM': 'courseItem/SUCCESS_DELETE_COURSE_ITEM',
  'ERROR_DELETE_COURSE_ITEM': 'courseItem/ERROR_DELETE_COURSE_ITEM',
};
