import Promise from 'bluebird';

// getCookie :: String -> String
export const getCookie = (cname) => {
  const name = `${cname}=`;
  const ca = document.cookie.split(';');

  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') c = c.substring(1);
    if (c.indexOf(name) == 0) return c.substring(name.length, c.length);
  }

  return '';
};

// setCookie :: String -> String -> Integer -> Promise
export const setCookie = (cname, cvalue, days) => {
  var d = new Date();
  d.setTime(d.getTime() + (days * 24 * 60 * 60 * 1000));
  var expires = `expires=${d.toUTCString()}`;
  return Promise.resolve(
    document.cookie = `${cname}=${cvalue};${expires};path=/`
  );
};

// deleteCookie :: String -> Promise
export const deleteCookie = (cname) => {
  return Promise.resolve(
    document.cookie = `${cname}=; Max-Age=-99999999;path=/`
  );
}
