export default {
  'REQUEST_IMAGE_COLLECTION': 'image/REQUEST_IMAGE_COLLECTION',
  'SUCCESS_IMAGE_COLLECTION': 'image/SUCCESS_IMAGE_COLLECTION',
  'ERROR_IMAGE_COLLECTION': 'image/ERROR_IMAGE_COLLECTION',

  'REQUEST_IMAGE': 'image/REQUEST_IMAGE',
  'SUCCESS_IMAGE': 'image/SUCCESS_IMAGE',
  'ERROR_IMAGE': 'image/ERROR_IMAGE',

  'UPDATE_IMAGE': 'image/UPDATE_IMAGE',
  'SUCCESS_UPDATE_IMAGE': 'image/SUCCESS_UPDATE_IMAGE',
  'ERROR_UPDATE_IMAGE': 'image/ERROR_UPDATE_IMAGE',

  'DELETE_IMAGE': 'image/DELETE_IMAGE',
  'SUCCESS_DELETE_IMAGE': 'image/SUCCESS_DELETE_IMAGE',
  'ERROR_DELETE_IMAGE': 'image/ERROR_DELETE_IMAGE',
};
