export default {
  'REQUEST_DOCUMENT_COLLECTION': 'document/REQUEST_DOCUMENT_COLLECTION',
  'SUCCESS_DOCUMENT_COLLECTION': 'document/SUCCESS_DOCUMENT_COLLECTION',
  'ERROR_DOCUMENT_COLLECTION': 'document/ERROR_DOCUMENT_COLLECTION',

  'REQUEST_DOCUMENT': 'document/REQUEST_DOCUMENT',
  'SUCCESS_DOCUMENT': 'document/SUCCESS_DOCUMENT',
  'ERROR_DOCUMENT': 'document/ERROR_DOCUMENT',

  'UPDATE_DOCUMENT': 'document/UPDATE_DOCUMENT',
  'SUCCESS_UPDATE_DOCUMENT': 'document/SUCCESS_UPDATE_DOCUMENT',
  'ERROR_UPDATE_DOCUMENT': 'document/ERROR_UPDATE_DOCUMENT',

  'DELETE_DOCUMENT': 'document/DELETE_DOCUMENT',
  'SUCCESS_DELETE_DOCUMENT': 'document/SUCCESS_DELETE_DOCUMENT',
  'ERROR_DELETE_DOCUMENT': 'document/ERROR_DELETE_DOCUMENT',
};
