import types from './types';

import httpRequestReducer from 'redux/modules/shared/httpRequestReducer';
import reduceReducers from 'redux/modules/shared/reduceReducers';

const initialState = {};

export default reduceReducers([
  httpRequestReducer(
    types.REQUEST_LOG_EVENT_COLLECTION,
    types.SUCCESS_LOG_EVENT_COLLECTION,
    types.ERROR_LOG_EVENT_COLLECTION,
  ),
  (state, action) => {
    switch (action.type) {
      default:
        return state;
    }
  }
], initialState);
