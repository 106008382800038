import React from 'react';
import injectSheet from 'react-jss';

import {
  contains,
  equals,
} from 'ramda';

import {
  Button,
  Form,
  Grid,
  Header,
  Image,
  Segment,
} from 'semantic-ui-react';

import colors from 'config/styles/colors';
import constants from 'utils/constants';
import {
  deleteCookie,
  getCookie,
  setCookie,
} from 'utils/cookie';

import PageLoader from 'components/PageLoader';

const styles = ({
  root: {
    flexGrow: 1,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: colors.itemBackground,
    marginBottom: 30,
  },
  logo: {
    height: 50,
    marginLeft: 10,
  },
  buttonContainer: {
    justifyContent: 'center',
  },
});

class Login extends React.Component {
  state = {
    username: '',
    password: ''
  };

  async componentDidUpdate({ tokenItem: prevTokenItem }, prevState) {
    const {
      tokenItem,
      history,
    } = this.props;

    if (equals(tokenItem, prevTokenItem)) { return; }

    const { token, role } = (
      tokenItem
      && tokenItem.payload
      && tokenItem.payload.data
    ) || {}

    if (!token || !role) { return; }

    if (getCookie('access_token') !== '') {
      deleteCookie('access_token');
    }
    if (getCookie('access_role') !== '') {
      deleteCookie('access_role');
    }

    await setCookie('access_token', token, 1)
    await setCookie('access_role', role, 1)

    if (role === constants.AdminRole.ADMIN) {
      return history.push('/course')
    }

    if (contains(role, [constants.AdminRole.CHIC, constants.AdminRole.PRDE])) {
      return history.push('/report/totalview')
    }

    return history.push('/')
  }

  handleChange = name => event => {
    this.setState({ [name]: event.target.value });
  };

  login = () => {
    const { username, password } = this.state;
    this.props.login(username, password);
  }

  render() {
    const { classes, tokenItem } = this.props;

    const LoaderComponent = (tokenItem && !tokenItem.isLoaded) ? (<PageLoader />) : null;

    const LoginError =
      tokenItem
      && tokenItem.payload
      && tokenItem.payload.error
      && tokenItem.payload.error.response
      && tokenItem.payload.error.response.data;

    return (
      <div className={classes.root} >
        <div className={classes.header}>
          <Image
            className={classes.logo}
            src={require('assets/images/logo.png')}
          />
        </div>
        <Grid
          textAlign='center'
          style={{ height: '100%' }}
          verticalAlign='middle'
        >
          <Grid.Column style={{ maxWidth: 450 }}>
            <Header as='h2' color='teal' textAlign='center'>
              {' '}Log-in to your account
            </Header>
            <Form size='large'>
              <Segment stacked>
                <Form.Input
                  fluid
                  icon='user'
                  iconPosition='left'
                  placeholder='Username'
                  onChange={this.handleChange('username')}
                />
                <Form.Input
                  fluid
                  icon='lock'
                  iconPosition='left'
                  placeholder='Password'
                  onChange={this.handleChange('password')}
                  type='password'
                />

                {LoaderComponent}

                {LoginError}

                <Button
                  color='teal'
                  fluid
                  size='large'
                  onClick={this.login}
                >Login</Button>
              </Segment>
            </Form>
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

export default injectSheet(styles)(Login);
