export default {
  'REQUEST_APP_SESSION': 'report/REQUEST_APP_SESSION',
  'SUCCESS_APP_SESSION': 'report/SUCCESS_APP_SESSION',
  'ERROR_APP_SESSION': 'report/ERROR_APP_SESSION',

  'REQUEST_QUIZ_ANSWER': 'report/REQUEST_QUIZ_ANSWER',
  'SUCCESS_QUIZ_ANSWER': 'report/SUCCESS_QUIZ_ANSWER',
  'ERROR_QUIZ_ANSWER': 'report/ERROR_QUIZ_ANSWER',

  'REQUEST_QUIZ_ANSWER_ATTEMPT': 'report/REQUEST_QUIZ_ANSWER_ATTEMPT',
  'SUCCESS_QUIZ_ANSWER_ATTEMPT': 'report/SUCCESS_QUIZ_ANSWER_ATTEMPT',
  'ERROR_QUIZ_ANSWER_ATTEMPT': 'report/ERROR_QUIZ_ANSWER_ATTEMPT',

  'REQUEST_REGISTRATION': 'report/REQUEST_REGISTRATION',
  'SUCCESS_REGISTRATION': 'report/SUCCESS_REGISTRATION',
  'ERROR_REGISTRATION': 'report/ERROR_REGISTRATION',

  'REQUEST_SEARCH_USER': 'report/REQUEST_SEARCH_USER',
  'SUCCESS_SEARCH_USER': 'report/SUCCESS_SEARCH_USER',
  'ERROR_SEARCH_USER': 'report/ERROR_SEARCH_USER',

  'REQUEST_TOTAL_VIEW': 'report/REQUEST_TOTAL_VIEW',
  'SUCCESS_TOTAL_VIEW': 'report/SUCCESS_TOTAL_VIEW',
  'ERROR_TOTAL_VIEW': 'report/ERROR_TOTAL_VIEW',

  'REQUEST_TOTAL_VIEW_SESSION': 'report/REQUEST_TOTAL_VIEW_SESSION',
  'SUCCESS_TOTAL_VIEW_SESSION': 'report/SUCCESS_TOTAL_VIEW_SESSION',
  'ERROR_TOTAL_VIEW_SESSION': 'report/ERROR_TOTAL_VIEW_SESSION',

  'REQUEST_VIDEO_SESSION': 'report/REQUEST_VIDEO_SESSION',
  'SUCCESS_VIDEO_SESSION': 'report/SUCCESS_VIDEO_SESSION',
  'ERROR_VIDEO_SESSION': 'report/ERROR_VIDEO_SESSION',

  'REQUEST_VIDEO_TREND': 'report/REQUEST_VIDEO_TREND',
  'SUCCESS_VIDEO_TREND': 'report/SUCCESS_VIDEO_TREND',
  'ERROR_VIDEO_TREND': 'report/ERROR_VIDEO_TREND',

  'REQUEST_VIDEO_TREND_DETAIL': 'report/REQUEST_VIDEO_TREND_DETAIL',
  'SUCCESS_VIDEO_TREND_DETAIL': 'report/SUCCESS_VIDEO_TREND_DETAIL',
  'ERROR_VIDEO_TREND_DETAIL': 'report/ERROR_VIDEO_TREND_DETAIL',

  'REQUEST_COMPLETE_COURSE': 'report/REQUEST_COMPLETE_COURSE',
  'SUCCESS_COMPLETE_COURSE': 'report/SUCCESS_COMPLETE_COURSE',
  'ERROR_COMPLETE_COURSE': 'report/ERROR_COMPLETE_COURSE',

  'REQUEST_COURSE_OVERVIEW': 'report/REQUEST_COURSE_OVERVIEW',
  'SUCCESS_COURSE_OVERVIEW': 'report/SUCCESS_COURSE_OVERVIEW',
  'ERROR_COURSE_OVERVIEW': 'report/ERROR_COURSE_OVERVIEW',

  'SET_DATE': 'report/SET_DATE',
  'SET_COURSE': 'report/SET_COURSE',
  'SET_USER': 'report/SET_USER',
};
