import storeNames from './storeNames';

import audience from './audience';
import closedCaption from './closedCaption';
import cloudwatch from './cloudwatch';
import country from './country';
import course from './course';
import courseItem from './courseItem';
import document from './document';
import event from './event';
import image from './image';
import institution from './institution';
import instructor from './instructor';
import itemType from './itemType';
import language from './language';
import login from './login';
import program from './program';
import question from './question';
import questionAnswer from './questionAnswer';
import questionType from './questionType';
import quiz from './quiz';
import quizContext from './quizContext';
import report from './report';
import topic from './topic';
import video from './video';
import videoSource from './videoSource';

export default {
  [storeNames.AUDIENCE_STORE]: audience,
  [storeNames.CLOSED_CAPTION_STORE]: closedCaption,
  [storeNames.CLOUDWATCH_STORE]: cloudwatch,
  [storeNames.COUNTRY_STORE]: country,
  [storeNames.COURSE_ITEM_STORE]: courseItem,
  [storeNames.COURSE_STORE]: course,
  [storeNames.DOCUMENT_STORE]: document,
  [storeNames.EVENT_STORE]: event,
  [storeNames.IMAGE_STORE]: image,
  [storeNames.INSTITUTION_STORE]: institution,
  [storeNames.INSTRUCTOR_STORE]: instructor,
  [storeNames.ITEM_TYPE_STORE]: itemType,
  [storeNames.LANGUAGE_STORE]: language,
  [storeNames.LOGIN_STORE]: login,
  [storeNames.PROGRAM_STORE]: program,
  [storeNames.QUESTION_ANSWER_STORE]: questionAnswer,
  [storeNames.QUESTION_STORE]: question,
  [storeNames.QUESTION_TYPE_STORE]: questionType,
  [storeNames.QUIZ_CONTEXT_STORE]: quizContext,
  [storeNames.QUIZ_STORE]: quiz,
  [storeNames.REPORT_STORE]: report,
  [storeNames.TOPIC_STORE]: topic,
  [storeNames.VIDEO_SOURCE_STORE]: videoSource,
  [storeNames.VIDEO_STORE]: video,
};

