export default {
  'REQUEST_PROGRAM_COLLECTION': 'program/REQUEST_PROGRAM_COLLECTION',
  'SUCCESS_PROGRAM_COLLECTION': 'program/SUCCESS_PROGRAM_COLLECTION',
  'ERROR_PROGRAM_COLLECTION': 'program/ERROR_PROGRAM_COLLECTION',

  'REQUEST_PROGRAM': 'program/REQUEST_PROGRAM',
  'SUCCESS_PROGRAM': 'program/SUCCESS_PROGRAM',
  'ERROR_PROGRAM': 'program/ERROR_PROGRAM',

  'UPDATE_PROGRAM': 'program/UPDATE_PROGRAM',
  'SUCCESS_UPDATE_PROGRAM': 'program/SUCCESS_UPDATE_PROGRAM',
  'ERROR_UPDATE_PROGRAM': 'program/ERROR_UPDATE_PROGRAM',

  'DELETE_PROGRAM': 'program/DELETE_PROGRAM',
  'SUCCESS_DELETE_PROGRAM': 'program/SUCCESS_DELETE_PROGRAM',
  'ERROR_DELETE_PROGRAM': 'program/ERROR_DELETE_PROGRAM',
};
