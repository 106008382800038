import { postWOTokenAction } from 'redux/shared/httpRequest';

import types from './types';
import endpoints from './endpoints';
import storeKeys from './storeKeys';

export const login = (dispatch, username, password) =>
  postWOTokenAction(
    endpoints.login,
    {
      username: username,
      password: password
    })(
      dispatch,
      types.REQUEST_LOGIN,
      types.SUCCESS_LOGIN,
      types.ERROR_LOGIN,
      {
        reduxId: storeKeys.TOKEN
      }
    );

export const logout = (dispatch) =>
  dispatch({
    type: types.LOGOUT,
  })
