import types from './types';

import crudReducer from 'redux/modules/shared/crudReducer';
import reduceReducers from 'redux/modules/shared/reduceReducers';

const initialState = {};

export default reduceReducers([
  crudReducer(types, 'EVENT'),
  (state, action) => {
    switch (action.type) {
      default:
        return state;
    }
  }
], initialState);
