export default {
  'REQUEST_AUDIENCE_COLLECTION': 'audience/REQUEST_AUDIENCE_COLLECTION',
  'SUCCESS_AUDIENCE_COLLECTION': 'audience/SUCCESS_AUDIENCE_COLLECTION',
  'ERROR_AUDIENCE_COLLECTION': 'audience/ERROR_AUDIENCE_COLLECTION',

  'REQUEST_AUDIENCE': 'audience/REQUEST_AUDIENCE',
  'SUCCESS_AUDIENCE': 'audience/SUCCESS_AUDIENCE',
  'ERROR_AUDIENCE': 'audience/ERROR_AUDIENCE',

  'UPDATE_AUDIENCE': 'audience/UPDATE_AUDIENCE',
  'SUCCESS_UPDATE_AUDIENCE': 'audience/SUCCESS_UPDATE_AUDIENCE',
  'ERROR_UPDATE_AUDIENCE': 'audience/ERROR_UPDATE_AUDIENCE',

  'DELETE_AUDIENCE': 'audience/DELETE_AUDIENCE',
  'SUCCESS_DELETE_AUDIENCE': 'audience/SUCCESS_DELETE_AUDIENCE',
  'ERROR_DELETE_AUDIENCE': 'audience/ERROR_DELETE_AUDIENCE',
};
