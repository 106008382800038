export default ({
  AdminRole: {
    ADMIN: 'admin',
    CHIC: 'chic',
    PRDE: 'prde',
  },
  attachmentContextType: {
    QUESTION: 1,
    QUESTION_ANSWER: 2,
  },
  attachmentType: {
    AUDIO: 1,
    IMAGE: 2,
  },
  FileLocation: {
    AUDIO: 'audio',
    CAPTION: 'caption',
    DOCUMENT: 'document',
    IMAGE: 'image',
    POSTER: 'poster',
    THUMBNAIL: 'thumbnail',
    VIDEO: 'video',
  },
  ItemType: {
    IMAGE: 3,
    QUIZ: 4,
    VIDEO: 5,
    LABEL: 6,
    EXTERNAL_URL: 7,
    DOCUMENT: 8,
  },
  language: {
    EN: 1,
    HI: 2,
    XH: 3,
  },
  branchQuestion: {
    // Go to next branch if QuestionAnswer.nextquestionid = NEXT_BRANCH
    NEXT_BRANCH: 0,
    // Question is a minor question if Question.Order = MINOR_QUESTION (int(3))
    MINOR_QUESTION: 16777215,
    // Quiz is terminated if QuestionAnswer.nextquestionid = QUIZ_TERMINATION (int(11))
    QUIZ_TERMINATION: 4294967295,
  },
  questionType: {
    TEXT_ANSWER: 1,
    RATING: 2,
    MULTIPLE_CHOICE: 3,
    MULTIPLE_ANSWERS: 4,
  },
  quizContextType: {
    VIDEO: 1,
    COURSE_ITEM: 2,
  },
  resolution: [
    480,
    540,
    720,
  ],
  dataType: {
    BOOLEAN: 'boolean',
    NUMBER: 'number',
    PERCENTAGE: 'percentage',
  }
})
