import createPalette from '@material-ui/core/styles/createPalette'
import createMuiTheme from '@material-ui/core/styles/createMuiTheme'

import grey from '@material-ui/core/colors/grey';

const dmteal = {
  50: '#e7f3f3',
  100: '#c4e0e2',
  200: '#9dcbcf',
  300: '#75b6bc',
  400: '#58a7ad',
  500: '#3a979f',
  600: '#348f97',
  700: '#2c848d',
  800: '#257a83',
  900: '#186972',
  A100: '#acf5ff',
  A200: '#79efff',
  A400: '#46e9ff',
  A700: '#2ce6ff',
  'contrastDefaultColor': 'light',
};

const theme = createMuiTheme(
  {
    palette: {
      primary: grey,
      secondary: dmteal,
    },
    typography: {
      useNextVariants: true,
    },
  }
);

/** DANGER: Mutating MUI theme functions.
 * Existing getContrastText works improperly making text hard to read.
 * This is an interim solution until it's fix and the project is updated. */
const originalGetContrastText = theme.palette.getContrastText
theme.palette.getContrastText = color => {
  if (color === theme.palette.primary[500]) {
    return theme.palette.common.white
  }
  return originalGetContrastText(color)
}
/** END OF DANGER */

export default theme;
