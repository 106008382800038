export default {
  APP_SESSION: 'APP_SESSION',
  COMPLETE_COURSE: 'COMPLETE_COURSE',
  COMPLETE_COURSE_ITEM: 'COMPLETE_COURSE_ITEM',
  COURSE_OVERVIEW: 'COURSE_OVERVIEW',
  COURSE: 'COURSE',
  END_DATE: 'END_DATE',
  QUIZ_ANSWER: 'QUIZ_ANSWER',
  QUIZ_ANSWER_ATTEMPT: 'QUIZ_ANSWER_ATTEMPT',
  REGISTRATION: 'REGISTRATION',
  SEARCH_USER: 'SEARCH_USER',
  START_DATE: 'START_DATE',
  TOTAL_VIEW: 'TOTAL_VIEW',
  TOTAL_VIEW_SESSION: 'TOTAL_VIEW_SESSION',
  VIDEO_SESSION: 'VIDEO_SESSION',
  VIDEO_TREND: 'VIDEO_TREND',
  VIDEO_TREND_DETAIL: 'VIDEO_TREND_DETAIL',
};
