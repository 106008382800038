import React from 'react';
import {
  ClipLoader,
} from 'react-spinners';
import injectSheet from 'react-jss';
import colors from 'config/styles/colors';

const styles = ({
  container: {
    zIndex: 9999,
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.loaderBackground,
  },
});

const Component = ({
  classes,
  loading = true,
}) => loading && (
    <div
      className={classes.container}
    >
      <div
        className='sweet-loading'
      >
        <ClipLoader
          size={80}
          color={colors.primaryContrastShade1}
        />
      </div>
    </div>
  );

export default injectSheet(styles)(Component);
