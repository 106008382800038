import * as R from 'ramda';

import httpRequestReducer from './httpRequestReducer';
import reduceReducers from './reduceReducers';

/** crudReducer :: { String: String } -> String -> (State -> Action -> State) */
export default (types, TYPE) => {
  const defaultReduxId = `${TYPE}_COLLECTION`;

  return reduceReducers([
    httpRequestReducer(types[`REQUEST_${TYPE}`], types[`SUCCESS_${TYPE}`], types[`ERROR_${TYPE}`],
      (state, action) => {
        const reduxId = action.payload.reduxId || defaultReduxId;

        if (action.payload.data) {
          return ({
            ...state,
            [reduxId]: {
              isLoaded: true,
              successful: true,
              payload: {
                data: {
                  ...((state[reduxId] && state[reduxId].payload && state[reduxId].payload.data) || {}),
                  [action.payload.item || action.payload.data.id]: {
                    ...action.payload.data,
                  }
                }
              }
            }
          });
        }
      }),
    httpRequestReducer(types[`REQUEST_${TYPE}_COLLECTION`], types[`SUCCESS_${TYPE}_COLLECTION`], types[`ERROR_${TYPE}_COLLECTION`],
      (state, action) => {
        const reduxId = action.payload.reduxId || defaultReduxId;

        const data = action.payload && action.payload.data || [];

        const items = data.reduce((acc, item) => ({
          ...acc,
          [item.id]: item
        }), {});

        return ({
          ...state,
          [reduxId]: {
            isLoaded: true,
            successful: true,
            payload: { data: items }
          }
        });
      }),
    httpRequestReducer(types[`DELETE_${TYPE}`], types[`SUCCESS_DELETE_${TYPE}`], types[`ERROR_DELETE_${TYPE}`],
      (state, action) => {
        const reduxId = action.payload.reduxId || defaultReduxId;

        return ({
          ...state,
          [reduxId]: {
            isLoaded: true,
            successful: true,
            payload: {
              data:
                (state[reduxId] && state[reduxId].payload && state[reduxId].payload.data)
                  ? R.omit([action.payload.data], state[reduxId].payload.data)
                  : {}
            }
          }
        });
      }
    ),
    httpRequestReducer(types[`UPDATE_${TYPE}`], types[`SUCCESS_UPDATE_${TYPE}`], types[`ERROR_UPDATE_${TYPE}`],
      (state, action) => {
        const reduxId = action.payload.reduxId || defaultReduxId;

        if (action.payload.data) {
          // data can be a number or an object
          const data = (
            R.is(Number, action.payload.data)
              ? {
                [action.payload.data]: {
                  ...action.payload.item,
                  id: action.payload.data
                }
              }
              : {
                [action.payload.data.id]: {
                  ...action.payload.item,
                  ...action.payload.data
                }
              }
          );

          return ({
            ...state,
            [reduxId]: {
              isLoaded: true,
              successful: true,
              payload: {
                data: {
                  ...((state[reduxId] && state[reduxId].payload && state[reduxId].payload.data) || {}),
                  ...data,
                }
              }
            }
          });
        }
      }
    )
  ]);
}
