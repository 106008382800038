import types from './types';

import httpRequestReducer, { renewSuccessReducer } from 'redux/modules/shared/httpRequestReducer';
import reduceReducers from 'redux/modules/shared/reduceReducers';

import storeKeys from 'redux/modules/report/storeKeys';

export default reduceReducers([
  httpRequestReducer(
    types.REQUEST_APP_SESSION,
    types.SUCCESS_APP_SESSION,
    types.ERROR_APP_SESSION,
  ),
  httpRequestReducer(
    types.REQUEST_QUIZ_ANSWER,
    types.SUCCESS_QUIZ_ANSWER,
    types.ERROR_QUIZ_ANSWER,
  ),
  httpRequestReducer(
    types.REQUEST_QUIZ_ANSWER_ATTEMPT,
    types.SUCCESS_QUIZ_ANSWER_ATTEMPT,
    types.ERROR_QUIZ_ANSWER_ATTEMPT,
  ),
  httpRequestReducer(
    types.REQUEST_REGISTRATION,
    types.SUCCESS_REGISTRATION,
    types.ERROR_REGISTRATION,
  ),
  httpRequestReducer(
    types.REQUEST_SEARCH_USER,
    types.SUCCESS_SEARCH_USER,
    types.ERROR_SEARCH_USER,
    renewSuccessReducer,
  ),
  httpRequestReducer(
    types.REQUEST_TOTAL_VIEW,
    types.SUCCESS_TOTAL_VIEW,
    types.ERROR_TOTAL_VIEW,
  ),
  httpRequestReducer(
    types.REQUEST_TOTAL_VIEW_SESSION,
    types.SUCCESS_TOTAL_VIEW_SESSION,
    types.ERROR_TOTAL_VIEW_SESSION,
  ),
  httpRequestReducer(
    types.REQUEST_VIDEO_SESSION,
    types.SUCCESS_VIDEO_SESSION,
    types.ERROR_VIDEO_SESSION,
  ),
  httpRequestReducer(
    types.REQUEST_VIDEO_TREND,
    types.SUCCESS_VIDEO_TREND,
    types.ERROR_VIDEO_TREND,
  ),
  httpRequestReducer(
    types.REQUEST_VIDEO_TREND_DETAIL,
    types.SUCCESS_VIDEO_TREND_DETAIL,
    types.ERROR_VIDEO_TREND_DETAIL,
    renewSuccessReducer,
  ),
  httpRequestReducer(
    types.REQUEST_COMPLETE_COURSE,
    types.SUCCESS_COMPLETE_COURSE,
    types.ERROR_COMPLETE_COURSE,
    renewSuccessReducer,
  ),
  httpRequestReducer(
    types.REQUEST_COURSE_OVERVIEW,
    types.SUCCESS_COURSE_OVERVIEW,
    types.ERROR_COURSE_OVERVIEW,
    renewSuccessReducer,
  ),
  (state, action) => {
    switch (action.type) {
      case types.SET_COURSE:
        return {
          ...state,
          [`${action.payload.reportType}_${storeKeys.COURSE}`]: action && action.payload && action.payload.course,
        };
      case types.SET_DATE:
        return {
          ...state,
          [`${action.payload.reportType}_${storeKeys.START_DATE}`]: action && action.payload && action.payload.startDate,
          [`${action.payload.reportType}_${storeKeys.END_DATE}`]: action && action.payload && action.payload.endDate,
        };
      case types.SET_USER:
        return {
          ...state,
          [`${action.payload.reportType}_${storeKeys.USER}`]: action && action.payload && action.payload.user,
        };

      default:
        return state;
    }
  },
]);
