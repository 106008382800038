import { connect } from 'react-redux';

import { logout } from 'redux/modules/login/actions';

import Component from './components';

const mapStateToProps = null

const mapDispatchToProps = (dispatch) => ({
  resetInitialState: () => logout(dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
