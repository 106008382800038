export default {
  'REQUEST_CLOSED_CAPTION_COLLECTION': 'closedCaption/REQUEST_CLOSED_CAPTION_COLLECTION',
  'SUCCESS_CLOSED_CAPTION_COLLECTION': 'closedCaption/SUCCESS_CLOSED_CAPTION_COLLECTION',
  'ERROR_CLOSED_CAPTION_COLLECTION': 'closedCaption/ERROR_CLOSED_CAPTION_COLLECTION',

  'REQUEST_CLOSED_CAPTION': 'closedCaption/REQUEST_CLOSED_CAPTION',
  'SUCCESS_CLOSED_CAPTION': 'closedCaption/SUCCESS_CLOSED_CAPTION',
  'ERROR_CLOSED_CAPTION': 'closedCaption/ERROR_CLOSED_CAPTION',

  'UPDATE_CLOSED_CAPTION': 'closedCaption/UPDATE_CLOSED_CAPTION',
  'SUCCESS_UPDATE_CLOSED_CAPTION': 'closedCaption/SUCCESS_UPDATE_CLOSED_CAPTION',
  'ERROR_UPDATE_CLOSED_CAPTION': 'closedCaption/ERROR_UPDATE_CLOSED_CAPTION',

  'DELETE_CLOSED_CAPTION': 'closedCaption/DELETE_CLOSED_CAPTION',
  'SUCCESS_DELETE_CLOSED_CAPTION': 'closedCaption/SUCCESS_DELETE_CLOSED_CAPTION',
  'ERROR_DELETE_CLOSED_CAPTION': 'closedCaption/ERROR_DELETE_CLOSED_CAPTION',
};
