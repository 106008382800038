// greyish
const primary = '#FAFAFA';
const primaryShade1 = '#E9F3F3';
const primaryShade2 = '#CECECE';
const primaryShade3 = '#A2A2A2';

const primaryContrastShade1 = '#4E4E4E';
const primaryContrastShade2 = '#343434';
const primaryContrastShade3 = '#747676';
const primaryContrastShade4 = '#6F6F6F';

//greenish
const secondary = '#3A979F';
const secondaryShade1 = '#A3D5D8';
const secondaryShade2 = '#F9F6FE';
const secondaryShade3 = '#ECF4F5';
const secondaryShade4 = '#95989A';
const secondaryShade5 = '#c1ffff';
const secondaryShade6 = '#66CCCC';
const secondaryShade7 = '#B9E6E6';
const secondaryShade8 = '#3B9EA3';

const white = '#FFFFFF';
const black = '#000000';
const transparent = '#00000000'

const red = '#bb3030';

const transparencyCode = 'DB'; // 86%
const transparencyCode1 = '99'; // 60%
const transparencyCode2 = '8A'; // 54%

const colors = {
  button: secondaryShade8,
  cardContainerBackground: secondaryShade7,
  dragBackground: secondaryShade3,
  itemBackground: white,
  itemBorder: primaryContrastShade1,
  itemText: primaryContrastShade2,
  lockedItemBackground: primaryShade1,
  menuText: primaryShade3,
  siteBackground: secondaryShade1,
  transparent,
  loaderBackground: `${black}${transparencyCode1}`,
};

export default colors;
