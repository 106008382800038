export default {
  'REQUEST_QUIZ_COLLECTION': 'quiz/REQUEST_QUIZ_COLLECTION',
  'SUCCESS_QUIZ_COLLECTION': 'quiz/SUCCESS_QUIZ_COLLECTION',
  'ERROR_QUIZ_COLLECTION': 'quiz/ERROR_QUIZ_COLLECTION',

  'REQUEST_QUIZ': 'quiz/REQUEST_QUIZ',
  'SUCCESS_QUIZ': 'quiz/SUCCESS_QUIZ',
  'ERROR_QUIZ': 'quiz/ERROR_QUIZ',

  'UPDATE_QUIZ': 'quiz/UPDATE_QUIZ',
  'SUCCESS_UPDATE_QUIZ': 'quiz/SUCCESS_UPDATE_QUIZ',
  'ERROR_UPDATE_QUIZ': 'quiz/ERROR_UPDATE_QUIZ',

  'DELETE_QUIZ': 'quiz/DELETE_QUIZ',
  'SUCCESS_DELETE_QUIZ': 'quiz/SUCCESS_DELETE_QUIZ',
  'ERROR_DELETE_QUIZ': 'quiz/ERROR_DELETE_QUIZ',
};
