export default {
  'REQUEST_LANGUAGE_COLLECTION': 'language/REQUEST_LANGUAGE_COLLECTION',
  'SUCCESS_LANGUAGE_COLLECTION': 'language/SUCCESS_LANGUAGE_COLLECTION',
  'ERROR_LANGUAGE_COLLECTION': 'language/ERROR_LANGUAGE_COLLECTION',

  'REQUEST_LANGUAGE': 'language/REQUEST_LANGUAGE',
  'SUCCESS_LANGUAGE': 'language/SUCCESS_LANGUAGE',
  'ERROR_LANGUAGE': 'language/ERROR_LANGUAGE',

  'UPDATE_LANGUAGE': 'language/UPDATE_LANGUAGE',
  'SUCCESS_UPDATE_LANGUAGE': 'language/SUCCESS_UPDATE_LANGUAGE',
  'ERROR_UPDATE_LANGUAGE': 'language/ERROR_UPDATE_LANGUAGE',

  'DELETE_LANGUAGE': 'language/DELETE_LANGUAGE',
  'SUCCESS_DELETE_LANGUAGE': 'language/SUCCESS_DELETE_LANGUAGE',
  'ERROR_DELETE_LANGUAGE': 'language/ERROR_DELETE_LANGUAGE',
};
