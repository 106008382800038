import {
  applyMiddleware,
  combineReducers,
  createStore,
} from 'redux';
import thunk from 'redux-thunk';
import {
  routerMiddleware,
} from 'react-router-redux';
import createHistory from 'history/createBrowserHistory';
import {
  composeWithDevTools,
} from 'redux-devtools-extension/developmentOnly';

import reducers from './modules';
import types from './modules/login/types';

const enhancer = (...middleware) => composeWithDevTools(
  // Middleware you want to use in development:
  applyMiddleware(...middleware),
);

const appReducer = combineReducers(reducers);

const rootReducer = (state, action) => {
  if (action.type === types.LOGOUT) {
    state = undefined
  }

  return appReducer(state, action)
}

export default function configureStore(initialState) {
  const store = createStore(
    rootReducer,
    initialState,
    enhancer(
      routerMiddleware(createHistory()),
      thunk
    )
  );

  // Hot reload reducers (requires Webpack or Browserify HMR to be enabled)
  if (module.hot) {
    module.hot.accept('./modules', () =>
      store.replaceReducer(require('./modules')/* .default if you use Babel 6+ */)
    );
  }

  return store;
}
