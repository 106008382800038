import React from 'react';
import {
  Link,
  withRouter
} from 'react-router-dom';

import injectSheet from 'react-jss';

import { Divider, Dropdown, Image } from 'semantic-ui-react';
import {
  deleteCookie,
  getCookie,
} from 'utils/cookie';

import colors from 'config/styles/colors';
import constants from 'utils/constants';

const textStyles = {
  textTransform: 'uppercase',
  textDecoration: 'none',
  fontSize: 14,
  fontWeight: 'bold',
  color: colors.menuText,
  '&:hover': {
    color: colors.menuText,
  },
};

const styles = ({
  container: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: colors.itemBackground,
  },
  logo: {
    height: 50,
    marginLeft: 10,
  },
  linkContainer: {
    flexGrow: 1,
    marginLeft: 50,
  },
  link: {
    ...textStyles,
    margin: [0, 15],
  },
  logout: {
    ...textStyles,
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'flex-end',
    margin: [0, 50, 0, 0],
  },
});

const AppNav = ({
  classes,
  history: { push },
  resetInitialState,
}) => {
  const role = getCookie('access_role')

  return (
    <div className={classes.container}>
      <Link to="/">
        <Image className={classes.logo} src={require('assets/images/logo.png')} />
      </Link>
      <div className={classes.linkContainer}>
        {
          role === constants.AdminRole.ADMIN && (
            <span>
              <Link
                className={classes.link}
                to='/course'
              >
                course
              </Link>
              <Link
                className={classes.link}
                to='/quiz'
              >
                quiz
              </Link>
              <Dropdown
                className={classes.link}
                text='checkup'
              >
                <Dropdown.Menu>
                  <Dropdown.Item
                    text='AWS'
                    onClick={() => push('/checkup/aws')}
                  />
                  <Dropdown.Item
                    text='App'
                    onClick={() => push('/checkup/app')}
                  />
                </Dropdown.Menu>
              </Dropdown>
              <Dropdown
                className={classes.link}
                text='lookups'
              >
                <Dropdown.Menu>
                  <Dropdown.Item
                    text='Instructor'
                    onClick={() => push('/instructor')}
                  />
                </Dropdown.Menu>
              </Dropdown>
            </span>
          )
        }
        <Dropdown
          className={classes.link}
          text='analytics'
        >
          <Dropdown.Menu>
            <Dropdown.Item
              text='App Session'
              onClick={() => push('/report/appsession')}
            />
            <Dropdown.Item
              text='Registration'
              onClick={() => push('/report/registration')}
            />
            <Dropdown.Item
              text='Total Video Loads'
              onClick={() => push('/report/totalview')}
            />
            <Dropdown.Item
              text='Video Session'
              onClick={() => push('/report/videosession')}
            />
            <Dropdown.Item
              text='Video Trend'
              onClick={() => push('/report/videotrend')}
            />
            <Dropdown.Item
              text='Completed Courses'
              onClick={() => push('/report/coursecompletion')}
            />
            <Dropdown.Item
              text='Course Overview'
              onClick={() => push('/report/courseoverview')}
            />
            <Divider />
            <Dropdown.Item
              text='Quiz Answer'
              onClick={() => push('/report/quizAnswer')}
            />
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <span
        className={classes.logout}
        onClick={async () => {
          await deleteCookie('access_token')
          await deleteCookie('access_role')
          resetInitialState()
          push('/')
        }}
      >
        logout
      </span>
    </div>
  )
}

export default injectSheet(styles)(withRouter(AppNav));
