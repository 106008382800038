import axios from 'axios';
import * as R from 'ramda';

import { getCookie } from 'utils/cookie';

const httpAction = R.curry((
  httpFunction,
  dispatch,
  requestType,
  successType,
  errorType,
  extraData = {},
  dataTransform = a => a
) => {
  dispatch({ type: requestType, payload: extraData });

  return httpFunction()
    .then(dataTransform)
    .then(({ data }) => {
      if (data !== undefined) {
        return dispatch({
          type: successType,
          payload: {
            data,
            ...extraData
          }
        });
      }

      return dispatch({
        type: errorType,
        payload: {
          data,
          ...extraData
        }
      });
    })
    .catch(error => dispatch({
      type: errorType,
      payload: {
        error,
        ...extraData
      }
    }));
});

const axiosAction = (
  method,
  token,
  data,
  url,
  onUploadProgress,
) =>
  httpAction(() =>
    axios({
      method,
      url,
      data,
      headers: {
        ...(token ? { 'Authorization': token } : {}),
        'Content-Type': 'application/json'
      },
      onUploadProgress,
    })
  );

export const getAction = (
  dispatch,
  url,
  requestType,
  successType,
  errorType,
  extraData,
  dataTransform
) => axiosAction(
  'get',
  getCookie('access_token'),
  null,
  url)(
    dispatch,
    requestType,
    successType,
    errorType,
    extraData,
    dataTransform,
  );

export const postWOTokenAction = (
  url,
  data
) => axiosAction(
  'post',
  null,
  data,
  url);
;

export const postAction = (
  dispatch,
  url,
  data,
  requestType,
  successType,
  errorType,
  extraData,
  dataTransform,
  onUploadProgress,
) =>
  axiosAction(
    'post',
    getCookie('access_token'),
    data,
    url,
    onUploadProgress,
  )(
    dispatch,
    requestType,
    successType,
    errorType,
    extraData,
    dataTransform,
  );

export const putAction = (
  dispatch,
  url,
  data,
  requestType,
  successType,
  errorType,
  extraData,
  dataTransform
) => axiosAction(
  'put',
  getCookie('access_token'),
  data,
  url)(
    dispatch,
    requestType,
    successType,
    errorType,
    extraData,
    dataTransform,
  );

export const deleteAction = (
  dispatch,
  url,
  requestType,
  successType,
  errorType,
  extraData,
  dataTransform
) => axiosAction(
  'delete',
  getCookie('access_token'),
  null,
  url)(
    dispatch,
    requestType,
    successType,
    errorType,
    extraData,
    dataTransform,
  );
