const successReducer = (renew) => (state, action) => ({
  ...state,
  [action.payload.reduxId]: {
    ...state[action.payload.reduxId],
    isLoaded: true,
    successful: true,
    renew,
    payload: action.payload
  }
});

export const renewSuccessReducer = successReducer(true);

const defaultSuccessReducer = successReducer(false);

export default (
  requestType,
  successType,
  errorType,
  successReducer = defaultSuccessReducer
) => (state = {}, action) => {
  switch (action.type) {
    case requestType:
      if (!action.payload.reduxId || (state[action.payload.reduxId] && state[action.payload.reduxId].successful)) {
        return state;
      };
      return {
        ...state,
        [action.payload.reduxId]: {
          ...state[action.payload.reduxId],
          isLoaded: false,
          successful: false
        }
      };

    case successType:
      return successReducer(state, action);

    case errorType:
      return {
        ...state,
        [action.payload.reduxId]: {
          ...state[action.payload.reduxId],
          isLoaded: true,
          successful: false,
          payload: action.payload
        }
      };

    default:
      return state;
  }
};
