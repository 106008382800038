import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import AppNav from './AppNav';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    minHeight: '100vh',
    width: '100%',
  },
  content: {
    display: 'flex',
    flex: '1 1 100%',
    flexWrap: 'wrap',
    flexDirection: 'column',
    justifyContent: 'stretch',
    maxWidth: '100%',
    marginBottom: 100,
    marginLeft: 200,
    marginRight: 200,
    paddingTop: 35,
  },
});

const Layout = ({
  classes,
  children
}) => (
  <div className={classes.root}>
    <AppNav />
    <div
      className={classes.content}
    >
      {children}
    </div>
  </div>
);

export default withStyles(styles)(Layout);
