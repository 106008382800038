export default {
  'REQUEST_QUIZ_CONTEXT_COLLECTION': 'quizContext/REQUEST_QUIZ_CONTEXT_COLLECTION',
  'SUCCESS_QUIZ_CONTEXT_COLLECTION': 'quizContext/SUCCESS_QUIZ_CONTEXT_COLLECTION',
  'ERROR_QUIZ_CONTEXT_COLLECTION': 'quizContext/ERROR_QUIZ_CONTEXT_COLLECTION',

  'REQUEST_QUIZ_CONTEXT': 'quizContext/REQUEST_QUIZ_CONTEXT',
  'SUCCESS_QUIZ_CONTEXT': 'quizContext/SUCCESS_QUIZ_CONTEXT',
  'ERROR_QUIZ_CONTEXT': 'quizContext/ERROR_QUIZ_CONTEXT',

  'UPDATE_QUIZ_CONTEXT': 'quizContext/UPDATE_QUIZ_CONTEXT',
  'SUCCESS_UPDATE_QUIZ_CONTEXT': 'quizContext/SUCCESS_UPDATE_QUIZ_CONTEXT',
  'ERROR_UPDATE_QUIZ_CONTEXT': 'quizContext/ERROR_UPDATE_QUIZ_CONTEXT',

  'DELETE_QUIZ_CONTEXT': 'quizContext/DELETE_QUIZ_CONTEXT',
  'SUCCESS_DELETE_QUIZ_CONTEXT': 'quizContext/SUCCESS_DELETE_QUIZ_CONTEXT',
  'ERROR_DELETE_QUIZ_CONTEXT': 'quizContext/ERROR_DELETE_QUIZ_CONTEXT',
};
