export default {
  'REQUEST_COURSE_COLLECTION': 'course/REQUEST_COURSE_COLLECTION',
  'SUCCESS_COURSE_COLLECTION': 'course/SUCCESS_COURSE_COLLECTION',
  'ERROR_COURSE_COLLECTION': 'course/ERROR_COURSE_COLLECTION',

  'REQUEST_COURSE': 'course/REQUEST_COURSE',
  'SUCCESS_COURSE': 'course/SUCCESS_COURSE',
  'ERROR_COURSE': 'course/ERROR_COURSE',

  'UPDATE_COURSE': 'course/UPDATE_COURSE',
  'SUCCESS_UPDATE_COURSE': 'course/SUCCESS_UPDATE_COURSE',
  'ERROR_UPDATE_COURSE': 'course/ERROR_UPDATE_COURSE',

  'DELETE_COURSE': 'course/DELETE_COURSE',
  'SUCCESS_DELETE_COURSE': 'course/SUCCESS_DELETE_COURSE',
  'ERROR_DELETE_COURSE': 'course/ERROR_DELETE_COURSE',
};
