import deepmerge from 'deepmerge';

import types from './types';

import crudReducer from 'redux/modules/shared/crudReducer';
import httpRequestReducer from 'redux/modules/shared/httpRequestReducer';
import reduceReducers from 'redux/modules/shared/reduceReducers';

const initialState = {};

export default reduceReducers([
  crudReducer(types, 'COURSE_ITEM'),
  (state, action) => {
    switch (action.type) {
      case types.SUCCESS_TRANSCODE_VIDEO:
        return ({
          ...state,
          [action.payload.reduxId]:
            deepmerge(
              state[action.payload.reduxId],
              {
                payload: {
                  data: {
                    [action.payload.item]: {
                      data: {
                        hls: action.payload.data,
                      },
                    },
                  },
                },
              }
            ),
        });
      default:
        return state;
    }
  }
], initialState);
