export default {
  'REQUEST_QUESTION_TYPE_COLLECTION': 'questionType/REQUEST_QUESTION_TYPE_COLLECTION',
  'SUCCESS_QUESTION_TYPE_COLLECTION': 'questionType/SUCCESS_QUESTION_TYPE_COLLECTION',
  'ERROR_QUESTION_TYPE_COLLECTION': 'questionType/ERROR_QUESTION_TYPE_COLLECTION',

  'REQUEST_QUESTION_TYPE': 'questionType/REQUEST_QUESTION_TYPE',
  'SUCCESS_QUESTION_TYPE': 'questionType/SUCCESS_QUESTION_TYPE',
  'ERROR_QUESTION_TYPE': 'questionType/ERROR_QUESTION_TYPE',

  'UPDATE_QUESTION_TYPE': 'questionType/UPDATE_QUESTION_TYPE',
  'SUCCESS_UPDATE_QUESTION_TYPE': 'questionType/SUCCESS_UPDATE_QUESTION_TYPE',
  'ERROR_UPDATE_QUESTION_TYPE': 'questionType/ERROR_UPDATE_QUESTION_TYPE',

  'DELETE_QUESTION_TYPE': 'questionType/DELETE_QUESTION_TYPE',
  'SUCCESS_DELETE_QUESTION_TYPE': 'questionType/SUCCESS_DELETE_QUESTION_TYPE',
  'ERROR_DELETE_QUESTION_TYPE': 'questionType/ERROR_DELETE_QUESTION_TYPE',
};
