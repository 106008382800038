import React from 'react';
import { Switch, BrowserRouter as Router, Route } from 'react-router-dom';
import importedComponent from 'react-imported-component';
import { withStyles } from '@material-ui/core/styles';
import withRoot from 'components/withRoot';
import colors from 'config/styles/colors';

import Layout from './Layout';
import Home from './Login';
import PageLoader from 'components/PageLoader';

const AsyncCoursePage = importedComponent(
  () => import(/* webpackChunkName:'DashboardPage' */ './Course'),
  {
    LoadingComponent: PageLoader
  }
);

const AsyncCourseItemPage = importedComponent(
  () => import(/* webpackChunkName:'DashboardPage' */ './CourseItem'),
  {
    LoadingComponent: PageLoader
  }
);

const AsyncInstructorPage = importedComponent(
  () => import(/* webpackChunkName:'DashboardPage' */ './Instructor'),
  {
    LoadingComponent: PageLoader
  }
);

const AsyncQuizPage = importedComponent(
  () => import(/* webpackChunkName:'DashboardPage' */ './Quiz'),
  {
    LoadingComponent: PageLoader
  }
);

const AsyncQuestionPage = importedComponent(
  () => import(/* webpackChunkName:'DashboardPage' */ './Question'),
  {
    LoadingComponent: PageLoader
  }
);

const AsyncQuestionAnswerPage = importedComponent(
  () => import(/* webpackChunkName:'DashboardPage' */ './QuestionAnswer'),
  {
    LoadingComponent: PageLoader
  }
);

const AsyncReportPage = importedComponent(
  () => import(/* webpackChunkName:'DashboardPage' */ './Report'),
  {
    LoadingComponent: PageLoader
  }
);

const AsyncCheckupPage = importedComponent(
  () => import(/* webpackChunkName:'CheckupPage' */ './Checkup'),
  {
    LoadingComponent: PageLoader
  }
);

const AsyncNoMatch = importedComponent(
  () => import('./NoMatch'),
  {
    LoadingComponent: PageLoader
  }
);

const styles = theme => ({
  '@global': {
    html: {
      WebkitFontSmoothing: 'antialiased', // Antialiasing.
      MozOsxFontSmoothing: 'grayscale', // Antialiasing.
      boxSizing: 'border-box',
    },
    '*, *:before, *:after': {
      boxSizing: 'inherit',
    },
    body: {
      margin: 0,
      padding: 0,
      fontSize: 14,
      lineHeight: 1.5,
      fontWeight: 'normal',
      backgroundColor: colors.siteBackground,
    },
    '#nprogress': {
      pointerEvents: 'none',
      '& .bar': {
        position: 'fixed',
        borderRadius: 1,
        zIndex: theme.zIndex.tooltip,
        top: 0,
        left: 0,
        width: '100%',
        height: 2,
      },
      '& dd, & dt': {
        position: 'absolute',
        top: 0,
        height: 2,
        borderRadius: '100%',
        animation: 'nprogress-pulse 2s ease-out 0s infinite',
      },
      '& dd': {
        opacity: 0.6,
        width: 20,
        right: 0,
        clip: 'rect(-6px,22px,14px,10px)',
      },
      '& dt': {
        opacity: 0.6,
        width: 180,
        right: -80,
        clip: 'rect(-6px,90px,14px,-6px)',
      },
    },
    '@keyframes nprogress-pulse': {
      '30%': {
        opacity: 0.6,
      },
      '60%': {
        opacity: 0,
      },
      to: {
        opacity: 0.6,
      },
    },
  },
  drawer: {
    [theme.breakpoints.up('lg')]: {
      width: 250,
    },
  },
});

const Index = () => {
  return (
    <Router>
      <Switch>
        <Route exact path='/' component={Home} />
        <Layout>
          <Switch>
            <Route path="/course" component={AsyncCoursePage} />
            <Route path="/courseitem" component={AsyncCourseItemPage} />
            <Route path="/instructor" component={AsyncInstructorPage} />
            <Route path="/quiz" component={AsyncQuizPage} />
            <Route path="/question" component={AsyncQuestionPage} />
            <Route path="/questionanswer" component={AsyncQuestionAnswerPage} />
            <Route path="/report" component={AsyncReportPage} />
            <Route path="/checkup" component={AsyncCheckupPage} />
            <Route component={AsyncNoMatch} />
          </Switch>
        </Layout>
      </Switch>
    </Router>
  );
};

export default withRoot(withStyles(styles)(Index));
