export default {
  'REQUEST_VIDEO_COLLECTION': 'video/REQUEST_VIDEO_COLLECTION',
  'SUCCESS_VIDEO_COLLECTION': 'video/SUCCESS_VIDEO_COLLECTION',
  'ERROR_VIDEO_COLLECTION': 'video/ERROR_VIDEO_COLLECTION',

  'REQUEST_VIDEO': 'video/REQUEST_VIDEO',
  'SUCCESS_VIDEO': 'video/SUCCESS_VIDEO',
  'ERROR_VIDEO': 'video/ERROR_VIDEO',

  'UPDATE_VIDEO': 'video/UPDATE_VIDEO',
  'SUCCESS_UPDATE_VIDEO': 'video/SUCCESS_UPDATE_VIDEO',
  'ERROR_UPDATE_VIDEO': 'video/ERROR_UPDATE_VIDEO',

  'DELETE_VIDEO': 'video/DELETE_VIDEO',
  'SUCCESS_DELETE_VIDEO': 'video/SUCCESS_DELETE_VIDEO',
  'ERROR_DELETE_VIDEO': 'video/ERROR_DELETE_VIDEO',

  'TRANSCODE_VIDEO': 'video/TRANSCODE_VIDEO',
  'SUCCESS_TRANSCODE_VIDEO': 'video/SUCCESS_TRANSCODE_VIDEO',
  'ERROR_TRANSCODE_VIDEO': 'video/ERROR_TRANSCODE_VIDEO',
};
