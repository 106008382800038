export default {
  'REQUEST_INSTRUCTOR_COLLECTION': 'instructor/REQUEST_INSTRUCTOR_COLLECTION',
  'SUCCESS_INSTRUCTOR_COLLECTION': 'instructor/SUCCESS_INSTRUCTOR_COLLECTION',
  'ERROR_INSTRUCTOR_COLLECTION': 'instructor/ERROR_INSTRUCTOR_COLLECTION',

  'REQUEST_INSTRUCTOR': 'instructor/REQUEST_INSTRUCTOR',
  'SUCCESS_INSTRUCTOR': 'instructor/SUCCESS_INSTRUCTOR',
  'ERROR_INSTRUCTOR': 'instructor/ERROR_INSTRUCTOR',

  'UPDATE_INSTRUCTOR': 'instructor/UPDATE_INSTRUCTOR',
  'SUCCESS_UPDATE_INSTRUCTOR': 'instructor/SUCCESS_UPDATE_INSTRUCTOR',
  'ERROR_UPDATE_INSTRUCTOR': 'instructor/ERROR_UPDATE_INSTRUCTOR',

  'DELETE_INSTRUCTOR': 'instructor/DELETE_INSTRUCTOR',
  'SUCCESS_DELETE_INSTRUCTOR': 'instructor/SUCCESS_DELETE_INSTRUCTOR',
  'ERROR_DELETE_INSTRUCTOR': 'instructor/ERROR_DELETE_INSTRUCTOR',
};
