export default {
  'REQUEST_VIDEO_SOURCE_COLLECTION': 'videoSource/REQUEST_VIDEO_SOURCE_COLLECTION',
  'SUCCESS_VIDEO_SOURCE_COLLECTION': 'videoSource/SUCCESS_VIDEO_SOURCE_COLLECTION',
  'ERROR_VIDEO_SOURCE_COLLECTION': 'videoSource/ERROR_VIDEO_SOURCE_COLLECTION',

  'REQUEST_VIDEO_SOURCE': 'videoSource/REQUEST_VIDEO_SOURCE',
  'SUCCESS_VIDEO_SOURCE': 'videoSource/SUCCESS_VIDEO_SOURCE',
  'ERROR_VIDEO_SOURCE': 'videoSource/ERROR_VIDEO_SOURCE',

  'UPDATE_VIDEO_SOURCE': 'videoSource/UPDATE_VIDEO_SOURCE',
  'SUCCESS_UPDATE_VIDEO_SOURCE': 'videoSource/SUCCESS_UPDATE_VIDEO_SOURCE',
  'ERROR_UPDATE_VIDEO_SOURCE': 'videoSource/ERROR_UPDATE_VIDEO_SOURCE',

  'DELETE_VIDEO_SOURCE': 'videoSource/DELETE_VIDEO_SOURCE',
  'SUCCESS_DELETE_VIDEO_SOURCE': 'videoSource/SUCCESS_DELETE_VIDEO_SOURCE',
  'ERROR_DELETE_VIDEO_SOURCE': 'videoSource/ERROR_DELETE_VIDEO_SOURCE',
};
