export default {
  'REQUEST_QUESTION_COLLECTION': 'question/REQUEST_QUESTION_COLLECTION',
  'SUCCESS_QUESTION_COLLECTION': 'question/SUCCESS_QUESTION_COLLECTION',
  'ERROR_QUESTION_COLLECTION': 'question/ERROR_QUESTION_COLLECTION',

  'REQUEST_QUESTION': 'question/REQUEST_QUESTION',
  'SUCCESS_QUESTION': 'question/SUCCESS_QUESTION',
  'ERROR_QUESTION': 'question/ERROR_QUESTION',

  'UPDATE_QUESTION': 'question/UPDATE_QUESTION',
  'SUCCESS_UPDATE_QUESTION': 'question/SUCCESS_UPDATE_QUESTION',
  'ERROR_UPDATE_QUESTION': 'question/ERROR_UPDATE_QUESTION',

  'DELETE_QUESTION': 'question/DELETE_QUESTION',
  'SUCCESS_DELETE_QUESTION': 'question/SUCCESS_DELETE_QUESTION',
  'ERROR_DELETE_QUESTION': 'question/ERROR_DELETE_QUESTION',
};
