import { connect } from 'react-redux';

import { login } from 'redux/modules/login/actions';

import storeKeys from 'redux/modules/login/storeKeys';
import storeNames from 'redux/modules/storeNames';

import Component from './components';

const mapStateToProps = ({
  [storeNames.LOGIN_STORE]: {
    [storeKeys.TOKEN]: tokenItem
  },
}) => {
  return { tokenItem }
};

const mapDispatchToProps = (dispatch) => ({
  login: (username, password) => login(dispatch, username, password)
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
